import { Editor } from "@monaco-editor/react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useRef, useState } from "react";
import {
  PostProcessorConfigDTO,
  PostProcessorType,
  PostProcessorPriority,
  ObjectApi,
  BasicReaderInfo,
  PostprocessorApi,
} from "serviceapi";
import { Messages } from "primereact/messages";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { apisStore } from "context/GlobalStates";
import { DEFAULT_OPTIONS } from "service/HttpService";

interface PostProcessorsManagementProps {
  close(): void;
}

const PostProcessorsManagement = (props: PostProcessorsManagementProps) => {
  const [postProcessors, setPostProcessors] = useState<
    PostProcessorConfigDTO[]
  >([]);
  const [readers, setReaders] = useState<BasicReaderInfo[]>([]);

  const [selectedPostProcessor, setSelectedPostProcessor] =
    useState<Partial<PostProcessorConfigDTO | null>>(null);

  const [configInput, setConfigInput] = useState<string>("");
  const [configError, setConfigError] = useState<string | null>(null);

  const [
    createSelectedPostProcessorDialog,
    setCreateSelectedPostProcessorDialog,
  ] = useState<boolean>(false);
  const msgs = useRef<Messages>(null);

  const apiPostProcessors = (apisStore().dataCollector as any)
    .postprocessorApi as PostprocessorApi;

  const apiReaders = (apisStore().dataCollector as any).objectsApi as ObjectApi;

  const fetchReaders = async () => {
    try {
      const response = await apiReaders.getReaders(DEFAULT_OPTIONS);
      setReaders(response.data);
    } catch (error) {
      console.error("Error fetching readers:", error);
      msgs.current?.show({
        severity: "error",
        content: `Failed to fetch readers`,
        closable: false,
      });
    }
  };

  const handleGetPostProcessors = async () => {
    try {
      const response = await apiPostProcessors.getAllConfigs(DEFAULT_OPTIONS);
      setPostProcessors(response.data);
    } catch (error) {
      msgs.current?.show({
        sticky: false,
        severity: "error",
        content: `Error occurred while getting post processors`,
        closable: false,
      });
    }
  };

  const handleAddEditPostProcessor = async () => {
    const postProcessorType = selectedPostProcessor?.postProcessorType;
    const priority = selectedPostProcessor?.priority;

    if (!postProcessorType) {
      msgs.current?.show({
        sticky: false,
        severity: "error",
        content: `Post processor type is not selected`,
        closable: false,
      });
      return;
    }
    if (!priority) {
      msgs.current?.show({
        sticky: false,
        severity: "error",
        content: `Post processor priority is not selected`,
        closable: false,
      });
      return;
    }
    const readersArray = Array.from(selectedPostProcessor?.readers || []);
    if (
      !selectedPostProcessor?.readers ||
      selectedPostProcessor.readers === null
    ) {
      msgs.current?.show({
        sticky: false,
        severity: "error",
        content: `Invalid post processor readers: ${readersArray}`,
        closable: false,
      });
      return;
    }

    for (const postProcessor of postProcessors) {
      if (
        !selectedPostProcessor &&
        (postProcessor.config as { id: string }).id ===
          JSON.parse(configInput).id
      ) {
        msgs.current?.show({
          sticky: false,
          severity: "error",
          content: `Post processor with current id already exist`,
          closable: false,
        });
        return;
      }
    }

    try {
      const parsedConfig = JSON.parse(configInput);
      const newConfig: PostProcessorConfigDTO = {
        postProcessorType: postProcessorType,
        readers: new Set(selectedPostProcessor.readers),
        priority: priority,
        config: parsedConfig,
      };
      await apiPostProcessors.setConfig(true, newConfig, DEFAULT_OPTIONS);
    } catch (error) {
      msgs.current?.show({
        sticky: false,
        severity: "error",
        content: `Error occurred while adding post processor`,
        closable: false,
      });
    } finally {
      setSelectedPostProcessor(null);
      setCreateSelectedPostProcessorDialog(false);
      handleGetPostProcessors();
      setConfigError(null);
    }
  };

  const handleConfigCheck = (newValue: string) => {
    try {
      JSON.parse(newValue);
      setConfigError(null);
    } catch {
      setConfigError("Invalid JSON format");
    }
  };

  const handleConfigInputChange = (newValue: string | undefined) => {
    if (newValue !== undefined) {
      setConfigInput(newValue);
      handleConfigCheck(newValue);
    }
  };

  const handleDeleteProcessor = async (
    postProcessor: PostProcessorConfigDTO
  ) => {
    if (postProcessor.config) {
      const id = (postProcessor.config as { id: string }).id;
      await apiPostProcessors.deleteConfig(id, DEFAULT_OPTIONS);
      handleGetPostProcessors();
    }
  };

  const handleCancelAddEditPostProcessor = () => {
    setCreateSelectedPostProcessorDialog(false);
    setSelectedPostProcessor(null);
    setConfigInput("");
  };

  const handleChangeSelection = (e: PostProcessorConfigDTO | null) => {
    setCreateSelectedPostProcessorDialog(true);
    setSelectedPostProcessor(e);
    setConfigInput(JSON.stringify(e?.config, null, 2));
    handleConfigInputChange(JSON.stringify(e?.config, null, 2));

    if (e === null) {
      setCreateSelectedPostProcessorDialog(false);
    }
  };

  const handleAddPostProcessorButtonClick = () => {
    setSelectedPostProcessor(null);
    setConfigInput("");
    setCreateSelectedPostProcessorDialog(true);
    handleConfigCheck("");
    fetchReaders();
  };

  const handleDeletePostProcessor = (target: HTMLElement) => {
    const postProcessor = selectedPostProcessor as PostProcessorConfigDTO;
    if (postProcessor && postProcessor.config) {
      confirmPopup({
        target: target,
        message: (
          <div>
            <p>
              Are you sure you want to remove this post processor:
              {(postProcessor.config as { id: string }).id}?
            </p>
          </div>
        ),
        acceptLabel: "Remove",
        accept: () => {
          handleDeleteProcessor(postProcessor);
          setCreateSelectedPostProcessorDialog(false);
        },
        rejectLabel: "Cancel",
      });
    }
  };

  const handleOpenDialog = () => {
    setCreateSelectedPostProcessorDialog(true);
    fetchReaders();
    handleGetPostProcessors();
  };

  return (
    <div style={{ position: "absolute" }}>
      <Messages ref={msgs} className="absolute top-0 right-0 z-1" />
      <Dialog
        header="Post Processors Management"
        visible={true}
        onHide={props.close}
        style={{ height: "83vh", width: "80vW" }}
        onShow={handleOpenDialog}
      >
        <ConfirmPopup />
        <div className="flex flex-row gap-3">
          <div
            className="flex flex-row justify-content-between gap-2 border-right-1 pr-3"
            style={{ width: "30%" }}
          >
            <DataTable
              value={postProcessors}
              selection={selectedPostProcessor}
              selectionMode="single"
              onSelectionChange={(e) => handleChangeSelection(e.value)}
              className="w-full"
              size="small"
              style={{ height: "40rem" }}
              scrollable
              scrollHeight="40rem"
              metaKeySelection={false}
            >
              <Column
                body={(rowData) =>
                  rowData.config.title || `[${rowData.config.id}]`
                }
                header="Title"
                style={{ maxWidth: "25rem" }}
              />
            </DataTable>
            <div className="flex flex-column gap-2">
              <Button
                icon="pi pi-plus"
                className="p-button-success"
                tooltip="Add Post Processor"
                onClick={handleAddPostProcessorButtonClick}
              />
              <Button
                icon="pi pi-trash"
                tooltip="Delete Post Processor"
                className="p-button-secondary"
                onClick={(e) => {
                  handleDeletePostProcessor(e.target as HTMLElement);
                }}
              />
              <Button
                icon="pi pi-refresh"
                tooltip="Refresh Post Processors"
                className="p-button-secondary"
                onClick={handleGetPostProcessors}
              />
            </div>
          </div>
          <div className="p-grid p-fluid" style={{ width: "70%" }}>
            {createSelectedPostProcessorDialog && (
              <div>
                {selectedPostProcessor ? (
                  <h3>Edit Post Processor</h3>
                ) : (
                  <h3>Add Post Processor</h3>
                )}
                <div className="grid">
                  <div className="col w-5 p-md-4">
                    <label htmlFor="postProcessorType">
                      Post Processor Type
                    </label>
                    <Dropdown
                      options={Object.values(PostProcessorType).map((type) => ({
                        label: type,
                        value: type,
                      }))}
                      name="postProcessorType"
                      id="postProcessorType"
                      value={selectedPostProcessor?.postProcessorType || ""}
                      onChange={(e) =>
                        setSelectedPostProcessor({
                          ...selectedPostProcessor,
                          postProcessorType: e.value as PostProcessorType,
                        })
                      }
                      className="mt-1 mb-3"
                      placeholder="MEASUREMENTS/PRODUCTS"
                    />
                  </div>
                  <div className="col w-5 p-md-4">
                    <label htmlFor="priority">Priority</label>
                    <Dropdown
                      options={Object.values(PostProcessorPriority).map(
                        (priority) => ({ label: priority, value: priority })
                      )}
                      name="priority"
                      id="priority"
                      value={selectedPostProcessor?.priority || ""}
                      onChange={(e) =>
                        setSelectedPostProcessor({
                          ...selectedPostProcessor,
                          priority: e.value as PostProcessorPriority,
                        })
                      }
                      className="mt-1 mb-3"
                      placeholder="BEFORE/AFTER"
                    />
                  </div>
                  <div className="col p-md-4 w-5">
                    <label htmlFor="readers">Readers</label>
                    <div className="flex align-items-center">
                      <MultiSelect
                        options={readers.map((reader) => ({
                          label: reader.readerName,
                          value: reader.readerName,
                        }))}
                        value={selectedPostProcessor?.readers || []}
                        onChange={(e) =>
                          setSelectedPostProcessor({
                            ...selectedPostProcessor,
                            readers: e.value,
                          })
                        }
                        className="mt-1 mb-3 w-full"
                        placeholder="Select Readers"
                        display="chip"
                        filter
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <label htmlFor="config">Config</label>
                  <div
                    style={{
                      resize: "vertical",
                      overflow: "auto",
                      height: "50vh",
                    }}
                  >
                    <Editor
                      defaultLanguage="json"
                      value={configInput}
                      onChange={handleConfigInputChange}
                      theme="vs-dark"
                      className="mt-1"
                    />
                  </div>
                  {configError && (
                    <small className="p-error">{configError}</small>
                  )}
                </div>
                <div className="p-col-12 p-md-4">
                  <Button
                    label="Save"
                    onClick={handleAddEditPostProcessor}
                    disabled={!!configError}
                    className="p-button-success w-2 mt-3"
                  />
                  <Button
                    label="Cancel"
                    className="p-button w-3 mt-3 ml-2"
                    onClick={handleCancelAddEditPostProcessor}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PostProcessorsManagement;
